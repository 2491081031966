<template>
	<div class="enewal">
		<div class="top_wrap">
			<div class="top_content">
				<div class="top_line" @click="go_back">
					<div class="top_l_left"></div>
					<div class="top_l_right">续费设备</div>
				</div>
				<div class="top_down">
					稳定、安全、弹性、高性能的设备，实施保障您的出海电商
				</div>
			</div>
		</div>

		<div class="instructions">
			<h3>
				设备续费须知<i
					>(请认真阅读续费须知，快洋淘浏览器提供的设备皆受国家网络监管，请合法合规使用。)</i
				>
			</h3>
			<p>
				1.
				快洋淘浏览器提供的国内/外设备，均受国家网络部门监管，禁止访问国家明令禁止登录的敏感网站，不提供一切翻墙服务。
			</p>
			<p>
				2.
				设备购买后无法退换，频繁更换设备会有潜在安全风险，购买设备时请根据账号日常运营环境或根据注册资料所在地进行选择。
			</p>
		</div>

		<div class="enewalList">
			<a-table
				:columns="columns"
				:data-source="pageData"
				:pagination="false"
				:row-key="record => record.id">
				<div slot="price" slot-scope="text, record">
					<p class="price">¥{{ record.price }}/月</p>
				</div>
			</a-table>

			<div class="duration_wrap">
				<div class="cell_title">购买时长:</div>
				<div class="net_line">
					<div class="net_l_right">
						<div
							class="net_cell durationBox"
							:class="[
								check_durationindex == index
									? 'net_active_cell'
									: '',
							]"
							v-for="(item, index) in duration_list"
							:key="item.id"
							@click="check_duration(index)">
							{{ item.duration }}个{{ item.duration_unit }}
							<span v-if="item.send > 0"
								>送{{ item.send }}{{ item.send_unit }}</span
							>
							<div
								class="net_active_ico"
								v-show="check_durationindex == index"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="duration_wrap">
				<div class="cell_title">优惠劵:</div>

				<a-select
					@change="handle_coupon"
					class="coupon_select"
					placeholder="请选择优惠券">
					<a-select-option
						v-for="item in coupon_list"
						:key="item.id"
						:money="item.money"
						:value="item.id">
						{{ item.title }} 优惠金额: {{ item.money }}
					</a-select-option>
				</a-select>
			</div>
			<div class="payway_wrap">
				<div class="cell_title">支付方式:</div>
				<div class="cell_wrap">
					<div
						v-for="(item, index) in payway_list"
						:key="index"
						class="pay_cell"
						:class="{
							pay_active_cell: check_paywayindex === index,
						}"
						@click="check_payway(index)">
						<div
							class="pay_active_ico"
							v-show="check_paywayindex === index"></div>

						<img
							class="icon"
							width="22"
							height="22"
							:src="getPaymentIcon(item.id)"
							alt="" />
						<div class="cell_right">
							<div class="cell_r_top">
								{{ item.title }}
							</div>
							<div class="cell_r_down" v-if="item.id == 1">
								{{ balance }}元
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="btn" @click="submit">提交订单</div>
		</div>

		<a-modal
			title="请用微信扫一扫支付"
			v-model="wechat_modal"
			@ok="wechat_pop_handle">
			<template slot="footer">
				<a-button key="back" @click="wechat_modal = false">
					取消
				</a-button>
				<a-button
					key="submit"
					type="primary"
					@click="wechat_pop_handle">
					我已付款
				</a-button>
			</template>

			<div
				class="modal_chart"
				id="buy_equ_qrcode"
				ref="buy_equ_qrcode"></div>
		</a-modal>
	</div>
</template>
<script>
import {
	device_purchase_duration_list,
	device_renew_device,
	order_renewal_order,
} from "@/api/environment";
import {
	device_pay_channel,
	coupon_list,
	client_v1_pay,
	client_v1_pay_balance,
	order_info,
} from "@/api/const_manage";
import { user_info } from "@/api/login";
import QRCode from "qrcode2";
export default {
	name: "enewal",
	data() {
		return {
			did: "",
			pageData: [],
			// 时长
			duration_list: [],
			check_durationindex: 0,
			// 支付
			payway_list: [],
			namnnr: null,
			check_paywayindex: 0,
			// 优惠卷
			coupon_list: [],
			check_coupon_id: "",
			coupon_money: 0,
			// 续费列表
			columns: [
				{
					title: "设备名称",
					dataIndex: "device_name",
				},
				{
					title: "设备信息",
					dataIndex: "device_ip",
				},
				{
					title: "网络类型",
					dataIndex: "net_type",
				},
				{
					title: "设备归属",
					dataIndex: "area",
				},
				{
					title: "套餐名称",
					dataIndex: "product",
				},
				{
					title: "价格",
					dataIndex: "price",
					scopedSlots: { customRender: "price" },
				},
			],
			balance: 0,
			wechat_modal: false, //微信二维码弹窗
			alipay_tem: null, //支付宝支付表单
			ali_modal: false, //支付宝二维码弹窗
			pay_result: false, //付款状态弹窗
		};
	},
	created() {},
	mounted() {
		this.did = this.$route?.query?.id;
		if (!this.did) {
			this.$message.error("未获取到设备参数");
			this.go_back();
			return;
		}
		this.get_duration_data();
		this.get_payway();
		this.get_couponlist();
		this.get_device_renew_device();
	},
	methods: {
		go_back() {
			this.$router.push({
				name: "manage_equipment",
			});
		},
		check_payway(index) {
			this.check_paywayindex = index;
		},
		// 续费列表
		async get_device_renew_device() {
			let str = { device_id: this.did };
			let { data } = await device_renew_device(str);
			if (data.code === 2) {
				this.go_back();
				return;
			}
			this.pageData.push(data.data);
		},
		// 购买时长
		async get_duration_data() {
			let { data } = await device_purchase_duration_list();
			if (data.code == 200) {
				this.duration_list = data.data.list;
			}
		},
		//时长
		check_duration(index) {
			this.check_durationindex = index;
		},
		async get_userinfo() {
			let { data } = await user_info({
				user_role: JSON.parse(localStorage.member).user_role,
			});
			if (data.code == 200) {
				localStorage.member = JSON.stringify(data.data.member);
				this.balance = data.data.member.balance;
			}
		},
		wechat_pop_handle() {
			this.get_orderinfo();
		},
		// 支付方式
		async get_payway() {
			let { data } = await device_pay_channel();
			if (data.code == 200) {
				this.payway_list = data.data.list;
				this.get_userinfo();
			}
		},
		// 优惠券
		async get_couponlist() {
			let { data } = await coupon_list({
				pagesize: 200,
			});
			if (data.code == 200) {
				this.coupon_list = data.data;
			}
		},
		handle_coupon(value, opti) {
			this.check_coupon_id = value;
			this.coupon_money = parseFloat(opti.data.attrs.money);
		},
		async submit() {
			// this.pageData[0].price=this.pageData[0].price * 1
			// this.duration_list[this.check_durationindex].duration = this.duration_list[this.check_durationindex].duration * 1

			//   if (this.namnnr > this.pageData.price *this.duration_list[this.check_durationindex].duration) {
			let arr = {
				// 设备id
				device_id: this.did,
				// 购买时长id
				device_purchase_duration_id:
					this.duration_list[this.check_durationindex].id,
				// 支付方式
				pay_channel_id: this.payway_list[this.check_paywayindex].id,
				// 优惠券的id
				coupon_id: this.check_coupon_id,
				use_dyn_qr: 1, // 测试用
			};
			let { data } = await order_renewal_order(arr);
			if (data.code == 200) {
				this.order_id = data.data.id;
				if (this.payway_list[this.check_paywayindex].id == 1) {
					this.balance_handle_pay(data.data.key);
				}
				if (
					this.payway_list[this.check_paywayindex].id == 2 ||
					this.payway_list[this.check_paywayindex].id == 3
				) {
					this.ali_handle_pay(
						data.data.key,
						this.payway_list[this.check_paywayindex].id
					);
				}
			}
			//   }else{
			// 	  this.$message.error("余额不足！");
			//   }
		},
		//调用余额
		async balance_handle_pay(key) {
			let { data } = await client_v1_pay_balance({
				key: key,
			});
			if (data.code == 200) {
				this.$message.success("购买成功!");
				this.$router.push({ name: "manage_equipment" });
			}
		},
		//订单付款状态
		async get_orderinfo() {
			let { data } = await order_info({
				id: this.order_id,
			});
			if (data.code == 200) {
				if (data.data.status == 1) {
					this.$message.success("购买成功!");
					this.$router.push({ name: "manage_equipment" });
				}
				if (data.data.status == 0) {
					this.$message.warning("没有检测到付款,请稍后再试!");
				}
			}
		},
		creatQrCode(urldata) {
			this.$refs.buy_equ_qrcode.innerHTML = "";
			new QRCode(this.$refs.buy_equ_qrcode, {
				text: urldata, //页面地址 ,如果页面需要参数传递请注意哈希模式#
				width: 160,
				height: 160,
			});
		},
		//调用支付宝 微信
		async ali_handle_pay(key, type) {
			let { data } = await client_v1_pay({
				key: key,
			});
			if (data.code == 200) {
				if (data.data instanceof Array) {
					this.$router.push({ name: "manage_equipment" });
				} else {
					//微信
					if (type == 3) {
						this.wechat_modal = true;
						this.$nextTick(function () {
							this.creatQrCode(data.data.code_url);
						});
					}

					//支付宝
					if (type == 2) {
						this.alipay_tem = data.data.template;
						let dwSafari;
						dwSafari = window.open();
						dwSafari.document.open();
						let dataObj = data.data.template;
						dwSafari.document.write(
							"<html><head><title></title><meta charset='utf-8'><body>" +
								dataObj +
								"</body></html>"
						);
						dwSafari.document.forms[0].submit();
						dwSafari.document.close();
						this.pay_result = true;
					}
				}
			}
		},
		getPaymentIcon(id) {
			switch (+id) {
				case 1:
					return require("@/assets/images/equipment/pay_icon0.png");
				case 2:
					return require("@/assets/images/equipment/pay_icon1.png");
				case 3:
					return require("@/assets/images/equipment/pay_icon2.png");
				default:
					return "";
			}
		},
	},
};
</script>
<style lang="less" scoped>
.modal_chart {
	width: 160px;
	height: 160px;
	margin: 0 auto;
}

.enewal {
	min-width: 1200px;
	margin: 0 auto;
	margin-top: 20px;

	.top_wrap {
		width: 100%;
		max-width: 1879px;
		height: 134px;
		margin: 0 auto;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		background-image: url("../../../assets/img/enewalTop.png");

		.top_content {
			width: 1200px;

			margin: 0 auto;

			//border: 1px solid yellow;
			.top_line {
				display: flex;
				cursor: pointer;

				.top_l_left {
					width: 23px;
					height: 20px;
					margin-left: 95px;
					margin-top: 30px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 100%;
					background-image: url("../../../assets/img/equipment/jiantou.png");
				}

				.top_l_right {
					width: 100px;
					margin-left: 8px;
					margin-top: 28px;
					height: 24px;
					font-size: 24px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #ffffff;
					line-height: 24px;
				}
			}

			.top_down {
				margin-left: 128px;
				margin-top: 26px;
				height: 14px;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
				line-height: 14px;
			}
		}
	}

	.instructions {
		display: flex;
		background: #f0f4fe;
		border-radius: 3px;
		border: 1px solid #4c84ff;
		flex-direction: column;
		width: 1200px;
		margin: 20px auto 0 auto;
		padding: 12px 20px 12px 20px;

		h3 {
			font-size: 15px;
			font-weight: 500;
			color: #374567;
			line-height: 22px;

			i {
				font-size: 13px;
				font-weight: 400;
				color: #878fa7;
				line-height: 22px;
				font-style: normal;
				margin-left: 12px;
			}
		}

		p {
			font-size: 14px;
			font-weight: 400;
			color: #374567;
			line-height: 22px;
			margin: 0;
		}
	}

	.enewalList {
		display: flex;
		background: #fff;
		flex-direction: column;
		width: 1200px;
		margin: 20px auto 0 auto;
		padding: 20px 22px 40px 22px;
		box-shadow: 0px 0px 11px 0px rgba(193, 199, 203, 0.3);

		.duration_wrap {
			display: flex;
			margin-top: 21px;
			width: 100%;

			.cell_title {
				font-size: 14px;
				font-weight: 400;
				color: #374567;
				display: flex;
				align-items: center;
			}

			.net_line {
				display: flex;
				min-height: 30px;

				// border: 1px solid red;
				.net_l_left {
					width: 33px;
					flex: none;
					margin-left: 25px;
					height: 13px;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #374567;
					line-height: 30px;
					padding-top: 10px;
				}

				.net_l_right {
					display: flex;
					flex-wrap: wrap;
					margin-left: 15px;

					.net_cell {
						position: relative;
						min-width: 88px;
						padding-left: 17px;
						padding-right: 17px;
						height: 30px;
						margin-right: 30px;
						line-height: 30px;
						text-align: center;
						cursor: pointer;
						font-size: 14px;
						color: #374567;
						background: #f7f9fd;
						border: 1px solid #ecf1fc;
					}

					.net_active_cell {
						border: 1px solid #739afe;

						.net_active_ico {
							position: absolute;
							width: 20px;
							height: 20px;
							top: -10px;
							right: -10px;
							background-repeat: no-repeat;
							background-position: center;
							background-size: 100%;
							background-image: url("../../../assets/img/equipment/勾选@2x.png");
						}
					}
				}

				.durantion_input {
					margin-left: 13px;
					height: 30px;
					margin-top: 10px;
				}

				.durantion_input_left {
				}

				.durantion_input_right {
				}

				.net_tip {
					margin-top: 16px;
					margin-left: 11px;
					height: 12px;
					font-size: 12px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #abb4c3;
				}
			}
		}

		.payway_wrap {
			display: flex;
			margin-top: 21px;
			width: 100%;

			.cell_title {
				font-size: 14px;
				font-weight: 400;
				color: #374567;
				display: flex;
				align-items: center;
			}

			.cell_wrap {
				display: flex;
				margin-left: 15px;

				.pay_cell {
					position: relative;
					display: flex;
					align-items: center;
					width: 160px;
					margin-right: 16px;
					height: 64px;
					background: #f7f9fd;
					border: 1px solid #ecf1fc;
					cursor: pointer;
					padding-left: 16px;
					.cell_left {
						flex: none;
						width: 22px;
						height: 22px;
						margin-left: 20px;
						margin-top: 21px;
						background-repeat: no-repeat;
						background-position: center;
						background-size: 100%;
					}

					.left_ali {
						background-image: url("../../../assets/img/equipment/ali-lite@2x.png");
					}

					.left_wechat {
						background-image: url("../../../assets/img/equipment/wx-lite@2x.png");
					}

					.cell_right {
						margin-left: 16px;
						.cell_r_top {
							height: 15px;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #374567;
							line-height: 15px;
						}

						.cell_r_down {
							height: 12px;
							font-size: 12px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #abb4c3;
							line-height: 12px;
							margin-top: 8px;
						}
					}
				}

				.pay_active_cell {
					border: 1px solid #739afe;

					.pay_active_ico {
						position: absolute;
						width: 20px;
						height: 20px;
						top: -10px;
						left: 148px;
						background-repeat: no-repeat;
						background-position: center;
						background-size: 100%;
						background-image: url("../../../assets/img/equipment/勾选@2x.png");
					}
				}
			}

			.coupon_select {
				margin-top: 20px;
				margin-left: 21px;
				width: 300px;
			}

			.payway_down {
				margin-top: 20px;
				margin-left: 21px;
				display: flex;

				.payway_d_right {
					width: 16px;
					height: 16px;
					margin-top: 3px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: 100%;
					background-image: url("../../../assets/img/equipment/组件_注释@2x.png");
				}
			}
		}

		.durationBox {
			span {
				line-height: 18px;
				text-align: center;
				position: absolute;
				background: url(./images/durationBox.png) no-repeat center
					center;
				background-size: 100% 100%;
				left: -12px;
				top: -9px;
				width: 48px;
				height: 18px;
				font-size: 12px;
				color: #ff0000;
			}
		}

		.coupon_select {
			margin-left: 27px;
			width: 300px;
		}

		.btn {
			width: 120px;
			height: 44px;
			background: #4c84ff;
			border-radius: 2px;
			width: 120px;
			height: 44px;
			margin-top: 60px;
			font-size: 14px;
			font-weight: 500;
			color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}
}

.price {
	font-size: 14px;
	font-weight: 500;
	color: #ff8e43;
	line-height: 20px;
}
</style>
