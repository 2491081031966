<template>
	<div class="buyequ_wrap">
		<div class="top_wrap">
			<div class="top_content">
				<div class="top_line" @click="go_back">
					<div class="top_l_left"></div>
					<div class="top_l_right">购买设备</div>
				</div>
				<div class="top_down">
					下单前请认真阅读设备
					<span
						style="color: yellow; cursor: pointer"
						@click="open_board"
						>购买须知</span
					>， 快洋淘提供的设备皆受国家网络监管，请合法合规使用。
				</div>
			</div>
		</div>
		<a-modal title="购买须知" v-model="pop_board" @ok="pop_boardhandle">
			<p style="margin-bottom: 5px">
				快洋淘浏览器专业版提供的国内/外设备，均受国家网络部门监管，禁止访问国家明令禁止登录的敏感网站，不提供一切翻墙服务。
			</p>
			<p style="margin-bottom: 5px">
				设备购买后无法退换，频繁更换设备会有潜在安全风险，购买设备时请根据账号日常运营环境或根据注册资料所在地进行选择。
			</p>
			<p style="margin-bottom: 5px">
				在购买成功后，系统将在2小时内分配
				设备到您的账户。如需购买Lazada，daraz平台使用的设备，购买前请先联系客服。
			</p>
			<p style="margin-bottom: 5px">
				快洋淘浏览器专业版仅保证设备能够正常联网，无法保证设备是否能够注册或登录某些电商平台，购买前请根据各平台的政策谨慎选择设备。
			</p>
			<p style="margin-bottom: 5px">
				小众设备仅海外和北京地区无法做亚马逊视频认证。其他平台设备做亚马逊视频认证请提前联系客服了解相关注意事项
			</p>
			<p style="margin-bottom: 5px">
				快洋淘浏览器专业版仅提供设备使用服务，设备信息由云服务商提供，请根据各平台的政策谨慎选择设备。
			</p>
			<!-- <p>
        未通过认证的企业仅支持购买10个设备。<a
          href="/#/companydevice/companysetting"
          >立即认证</a
        >
      </p> -->
		</a-modal>
		<main>
			<div class="deviceInfo">
				<!-- 热门设备 -->
				<div class="block hotDeviceList">
					<div class="title">热门设备</div>
					<ul>
						<li
							v-for="(item, index) in hotDeviceList"
							:key="index"
							:class="{
								active:
									region_id == item.region_id &&
									area_id == item.area_id &&
									selectedNetType.type == 1,
							}"
							@click="selectedHotDevice = item">
							<div class="header">
								<img
									width="30"
									height="38"
									src="@/assets/images/equipment/icon_equipment.png"
									alt="" />
								<span>{{ item.device_title }}</span>
							</div>
							<div class="footer">
								<span class="region">{{ item.region }}</span>
								<span class="area">{{ item.area }}</span>
							</div>
							<img
								width="56"
								height="56"
								:src="
									require(`@/assets/images/equipment/icon_top${
										index + 1
									}.png`)
								"
								alt=""
								class="top" />
						</li>
					</ul>
				</div>
				<!-- 自定义网络 -->
				<div class="block diyNet">
					<div class="title">自定义网络</div>
					<ul>
						<li>
							<div class="left">类型：</div>
							<div class="right">
								<ul>
									<li
										v-for="(item, index) in deviceTypeList"
										:key="index"
										:class="{
											active:
												selectedNetType.id == item.id,
										}"
										@click="selectNetType(item)">
										{{ item.item }}
									</li>
								</ul>
							</div>
						</li>
						<li>
							<div class="left">地域：</div>
							<div class="right">
								<ul>
									<li
										v-for="(item, index) in allRegion"
										:key="index"
										:class="{
											active: region_id == item.region_id,
										}"
										@click="selectRegion(item)">
										{{ item.item }}
									</li>
								</ul>
							</div>
						</li>
						<li>
							<div class="left">地区：</div>
							<div class="right">
								<ul v-if="selectedRegion">
									<li
										v-for="(
											item, index
										) in selectedRegion.child"
										:key="index"
										:class="{
											active: area_id == item.area_id,
										}"
										@click="selectArea(item)">
										{{ item.item }}
									</li>
								</ul>
							</div>
						</li>
						<li>
							<div class="left">套餐：</div>
							<div class="right setMeal">
								<ul>
									<li
										v-for="(item, index) in selectedArea
											? selectedArea.product
											: []"
										:key="index"
										:class="{
											active:
												selectedSetMeal.id == item.id,
										}"
										@click="selectedSetMeal = item">
										<span>{{ item.name }}</span>
										<p>{{ item.price }}元/月</p>
									</li>
								</ul>
							</div>
						</li>
					</ul>
					<p class="tip">
						*本线路无法访问全球etsy、Lazada平台、全球joom平台、fnac平台、mercari平台、noon平台，部分线路访问亚马逊平台会出现图片无法上传
						的问题，请谨慎选择。eBay平台的店铺，近期请不要选择该区域的设备。拼多多用户建议优先选择中国设备。
					</p>
				</div>
				<!-- 购买时长 -->
				<div class="block duration">
					<div class="title">购买时长</div>
					<ul>
						<li>
							<div class="left">时长：</div>
							<div class="right">
								<ul>
									<li
										v-for="(item, index) in durationList"
										:key="index"
										:class="{
											active:
												selectedDuration.id == item.id,
										}"
										@click="selectedDuration = item">
										{{
											item.duration +
											"个" +
											item.duration_unit
										}}
										<span v-show="item.send">{{
											item.send + item.send_unit
										}}</span>
									</li>
								</ul>
							</div>
						</li>
						<li>
							<div class="left">数量：</div>
							<div class="right">
								<div class="count">
									<a-input-number
										type="number"
										:min="1"
										:max="
											selectedArea
												? selectedArea.count
												: 0
										"
										value="1"
										v-model="buy_count" />
									<span>
										*单次最多购买{{
											selectedArea
												? selectedArea.count
												: 0
										}}个设备</span
									>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<!-- 支付方式 -->
				<div class="block pay_method">
					<div class="title">支付方式</div>
					<ul class="payMethods">
						<li
							v-for="(item, index) in payMethods"
							:key="index"
							:class="{ active: payMethod.id == item.id }"
							@click="payMethod = item">
							<img
								width="22"
								height="22"
								:src="
									require(`@/assets/images/equipment/pay_icon${index}.png`)
								"
								alt="" />
							<div>
								<span>{{ item.title }}</span>
								<p v-if="item.title == '可用余额'">
									{{ balance }}
								</p>
								<p v-else>{{ item.desc }}</p>
							</div>
						</li>
					</ul>
					<div class="title">优惠券</div>
					<div class="coupon">
						<a-select
							style="width: 400px"
							placeholder="请选择优惠券"
							v-model="selectedCoupon">
							<a-select-option :value="undefined">
								不使用优惠券
							</a-select-option>
							<a-select-option
								v-for="(item, index) in couponList"
								:key="index"
								:value="item.id">
								{{ item.title }}
								<span>优惠金额：{{ item.money }}</span>
							</a-select-option>
						</a-select>
					</div>
					<!-- <div class="autoRenewal">
						<a-checkbox v-model="autoRenewal">
							到期自动续费
						</a-checkbox>
					</div> -->
				</div>
			</div>
			<div class="orderInfo">
				<div class="title">确认购买清单：</div>
				<ul class="info">
					<li>
						<span>设备信息：</span>
						<span>{{
							selectedHotDevice ? "运营商平台" : "自定义"
						}}</span>
					</li>
					<li>
						<span>配置：</span>
						<span>{{
							selectedHotDevice
								? selectedHotDevice.device_title
								: "自定义"
						}}</span>
					</li>
					<li>
						<span>类型：</span>
						<span>{{
							selectedNetType ? selectedNetType.item : ""
						}}</span>
					</li>
					<li>
						<span>地域：</span>
						<span>{{
							selectedRegion ? selectedRegion.item : ""
						}}</span>
					</li>
					<li>
						<span>地区：</span>
						<span>{{ selectedArea ? selectedArea.item : "" }}</span>
					</li>
					<li>
						<span>套餐：</span>
						<span>{{
							selectedSetMeal ? selectedSetMeal.name : ""
						}}</span>
					</li>
					<li>
						<span>支付方式：</span>
						<span>{{ payMethod ? payMethod.title : "" }}</span>
					</li>
					<li>
						<span>购买时长：</span>
						<span>{{ selectedDuration.duration }}个月</span>
					</li>
					<li>
						<span>购买数量：</span>
						<span>{{ buy_count }}</span>
					</li>
					<!-- <li>
						<span>自动续费：</span>
						<span>{{ autoRenewal ? "是" : "否" }}</span>
					</li> -->
				</ul>
				<div class="order">
					<div class="cost">
						<span class="title">优惠立减</span>
						<span>&yen;{{ selectCoupon() }}元</span>
					</div>
					<div class="cost">
						<span class="title">应付金额</span>
						<span>&yen;{{ totalPrice }}元</span>
					</div>
					<div class="btn" @click="order">立即购买</div>
					<div class="agree">
						<a-checkbox v-model="agreed"
							>我已阅读并同意快洋淘<b>《服务条款》</b></a-checkbox
						>
					</div>
				</div>
			</div>
		</main>
		<!-- 微信二维码弹窗 -->
		<!--  -->
		<a-modal
			:title="`请使用手机${
				payMethod?.id == 2 ? '支付宝' : '微信'
			}扫码支付`"
			:visible="wechatPay.show"
			:confirm-loading="wechatPay.loading"
			ok-text="我已付款"
			cancel-text="取消支付"
			:footer="null"
			@ok="checkPayStatus"
			@cancel="closeQr">
			<div class="qr_box" ref="qr_box"></div>
		</a-modal>
		<!-- 支付宝是否完成支付弹窗 -->
		<a-modal
			title="付款结果"
			:visible="aliPay.show"
			:confirm-loading="aliPay.loading"
			ok-text="我已付款"
			cancel-text="取消支付"
			@ok="checkPayStatus"
			@cancel="aliPay.show = false">
			<p>是否已完成付款？</p>
		</a-modal>
	</div>
</template>
<script>
import {
	initDeviceApi,
	deviceAreaApi,
	durationListApi,
	createOrderApi,
	payMethodsApi,
	couponListApi,
	userInfoApi,
	balancePayApi,
	onlinePayApi,
	orderInfoApi,
	refreshQrApi,
} from "@/api/equipment";

import { getList } from "@/api/equipment";
import {
	device_pay_channel,
	coupon_list,
	client_v1_pay,
	client_v1_pay_balance,
	order_info,
} from "@/api/const_manage";
import { certify_latest_info_v2 } from "@/api/authencation";
import { user_info } from "@/api/login";
import * as math from "mathjs";
import QRCode from "qrcode2";
export default {
	data() {
		return {
			pop_board: false,
			// 热门设备列表
			hotDeviceList: [],
			// 设备类型列表
			deviceTypeList: [],
			// 已选热门设备
			selectedHotDevice: null,
			// 已选网络类型
			selectedNetType: {},
			// 全部地区列表
			allRegion: [],
			// 选择的地域
			selectedRegion: null,
			// 选择的地区
			selectedArea: null,
			// 地区id
			region_id: null,
			area_id: null,
			// 时长列表
			durationList: [],
			// 默认选择时长
			selectedDuration: "",
			// 购买数量
			buy_count: 1,
			// 全部支付方式
			payMethods: [],
			// 选择的支付方式
			payMethod: null,
			// 优惠券列表
			couponList: [],
			// 用户余额
			balance: "",
			// 选择的优惠券
			selectedCoupon: undefined,
			// 到期自动续费
			autoRenewal: false,
			// 选择的套餐
			selectedSetMeal: null,
			// 同意条款
			agreed: false,
			orderInfo: {
				key: "",
				id: "",
			},
			// 微信支付弹窗
			wechatPay: {
				show: false,
				paid: false,
				loading: false,
			},
			aliPay: {
				show: false,
				paid: false,
				loading: false,
			},
			// 新窗口
			newTab: null,
			// 支付-key
			pay_key: "",
			reload_alipay_qr: false,
		};
	},
	mounted() {
		// 初始化设备列表
		this.initDeviceList();
		// 获取时长列表
		durationListApi().then(res => {
			if (res.data.code == 200) {
				this.durationList = res.data.data.list;
				this.selectedDuration = res.data.data.list[0];
			}
		});
		// 获取支付方式
		payMethodsApi().then(res => {
			this.payMethods = res.data.data.list;
			this.payMethod = res.data.data.list[0];
		});

		// 获取用户优惠券列表
		couponListApi().then(res => {
			this.couponList = res.data.data;
		});

		// 获取用户余额
		userInfoApi().then(res => {
			this.balance = res.data.data.member.balance;
		});
	},
	created() {},
	computed: {
		totalPrice() {
			let total = 0;
			let count = this.buy_count;
			let duration = this.selectedDuration.duration;
			let price = this.selectedSetMeal ? this.selectedSetMeal.price : 0;
			let discount = 0;
			if (this.selectedCoupon) {
				discount = this.couponList.find(
					item => item.id == this.selectedCoupon
				).money;
			}
			total = (count * duration * price - discount).toFixed(2);
			return total;
		},
	},
	watch: {
		// 切换热门设备
		selectedHotDevice: {
			handler(newVal) {
				if (newVal) {
					this.region_id = newVal.region_id;
					this.area_id = newVal.area_id;
					this.selectedNetType = this.deviceTypeList.find(
						item => item.type == 1
					);
					this.selectedRegion = this.allRegion.find(
						item => item.region_id == this.region_id
					);
					let { type, net_type_id } = newVal;
					this.getDeviceRegion(type, net_type_id);
				}
			},
		},
		// 自动选择套餐
		selectedArea: {
			handler(newVal) {
				this.selectedSetMeal = newVal.product[0];
			},
		},
	},
	methods: {
		go_back() {
			this.$router.back(-1);
		},
		open_board() {
			this.pop_board = true;
		},
		pop_boardhandle() {
			this.pop_board = false;
		},
		// 初始化设备列表
		initDeviceList() {
			initDeviceApi().then(res => {
				if (res.data.code == 200) {
					this.hotDeviceList = res.data.data.device_info;
					this.deviceTypeList = res.data.data.device_type;
					this.selectedHotDevice = res.data.data.device_info[0];
					this.selectedNetType = res.data.data.device_type.find(
						item => item.type == 1
					);
					let { type, net_type_id } = res.data.data.device_info[0];
					this.getDeviceRegion(type, net_type_id);
				}
			});
		},
		// 获取购买设备可选套餐地区
		getDeviceRegion(type, net_type_id) {
			deviceAreaApi({ type, net_type_id }).then(res => {
				if (res.data.code == 200) {
					this.allRegion = res.data.data;
					if (this.selectedHotDevice) {
						this.selectedRegion = this.allRegion.find(item => {
							return item.region_id == this.region_id;
						});
						this.selectedArea = this.selectedRegion.child.find(
							item => {
								return item.area_id == this.area_id;
							}
						);
					} else {
						this.selectedRegion = res.data.data[0];
						this.region_id = res.data.data[0].region_id;
						this.selectedArea = this.selectedRegion.child[0];
						this.area_id = this.selectedArea.area_id;
					}
				}
			});
		},
		// 手动选择地域
		selectRegion(item) {
			this.selectedHotDevice = null;
			this.region_id = item.region_id;
			this.selectedRegion = item;
			this.selectedArea = item.child[0];
			this.area_id = item.child[0].area_id;
		},
		// 手动选择地区
		selectArea(area) {
			this.selectedHotDevice = null;
			this.selectedArea = area;
			this.area_id = area.area_id;
		},
		// 手动选择网络类型
		selectNetType(item) {
			this.selectedHotDevice = null;
			this.selectedNetType = item;
			let { type, id } = item;
			this.getDeviceRegion(type, id);
		},
		// 优惠立减
		selectCoupon() {
			if (this.selectedCoupon) {
				let obj = this.couponList.find(
					item => item.id == this.selectedCoupon
				);
				return obj.money;
			} else {
				return "0.00";
			}
		},
		//
		// selectCount() {
		// 	if (this.buy_count) {
		// 		if (this.buy_count > this.selectedArea.count) {
		// 			this.buy_count = this.selectedArea.count;
		// 		} else if (this.buy_count < 1) {
		// 			this.buy_count = 1;
		// 		}
		// 	} else {
		// 		this.buy_count = 1;
		// 	}
		// },
		// 下单
		async order() {
			if (this.agreed) {
				// 创建订单
				try {
					let res_createOrer = await createOrderApi({
						net_type_id: this.selectedNetType.id,
						region_id: this.selectedRegion.region_id,
						area_id: this.selectedArea.area_id,
						product_id: this.selectedSetMeal.id,
						duration_id: this.selectedDuration.id,
						channel_id: this.payMethod.id,
						coupon_id: this.selectedCoupon,
						count: this.buy_count,
						auto_renew: this.autoRenewal ? 1 : 0,
						use_dyn_qr: 1, // 测试用
					});
					if (res_createOrer.data.code == 200) {
						this.orderInfo = res_createOrer.data.data;
						if (this.payMethod.id == 2) {
							// this.newTab = window.open();
						}
						// 调用支付
						if (this.payMethod.id == 1) {
							// 余额支付
							this.balancePay(res_createOrer.data.data.key);
						} else if (
							this.payMethod.id == 2 ||
							this.payMethod.id == 3
						) {
							// 调用微信支付宝支付
							// this.onlinePay(res_createOrer.data.data.key);
							this.wechatPay.show = true;
							this.reload_alipay_qr = true;
							this.pay_key = res_createOrer.data.data.key;
							this.$nextTick(() => {
								this.$refs.qr_box.innerHTML = "";
								new QRCode(this.$refs.qr_box, {
									text: res_createOrer.data.data.payment_url,
									width: 160,
									height: 160,
								});
							});
							setTimeout(() => {
								this.reloadAlipayQr();
							}, 5000);
						}
					}
				} catch {
					return;
				}
			} else {
				this.$message.warning("请阅读并同意服务条款");
			}
		},
		// 刷新支付宝二维码
		reloadAlipayQr() {
			refreshQrApi({ key: this.pay_key }).then(res => {
				if (res.data.code == 200) {
					this.pay_key = res.data.data.key;
					this.$refs.qr_box.innerHTML = "";
					new QRCode(this.$refs.qr_box, {
						text: res.data.data.payment_url,
						width: 160,
						height: 160,
					});
					setTimeout(() => {
						if (this.reload_alipay_qr) {
							this.reloadAlipayQr();
						}
					}, 5000);
				} else if (res.data.code == 219) {
					this.$refs.qr_box.innerHTML = `<span class="tip">已扫码，请在手机上完成支付</span>`;
				} else {
					this.wechatPay.show = false;
					this.$refs.qr_box.innerHTML = `<span class="tip">${res.data.msg}</span>`;
				}
			});
		},
		// 关闭二维码弹窗
		closeQr() {
			this.wechatPay.show = false;
			this.reload_alipay_qr = false;
			this.$router.push("/manage/equipment");
		},
		// 余额支付
		balancePay(key) {
			balancePayApi({ key }).then(res => {
				if (res.data.code == 200) {
					this.$message.success("购买成功!");
					this.$router.push({ name: "manage_equipment" });
				}
			});
		},
		// 微信支付宝支付
		onlinePay(key) {
			onlinePayApi({ key }).then(res => {
				if (res.data.code == 200) {
					if (this.payMethod.id == 2) {
						// 支付宝
						this.newTab.document.body.innerHTML =
							res.data.data.template;
						this.newTab.document.forms[0].submit();
						this.aliPay.show = true;
					} else {
						// 微信
						this.wechatPay.show = true;
						this.$nextTick(() => {
							this.$refs.qr_box.innerHTML = "";
							new QRCode(this.$refs.qr_box, {
								text: res.data.data.code_url,
								width: 160,
								height: 160,
							});
						});
					}
				}
			});
		},
		checkPayStatus() {
			// 检查支付状态
			this.wechatPay.loading = true;
			this.aliPay.loading = true;
			orderInfoApi({ id: this.orderInfo.id })
				.then(res => {
					if (res.data.data.status == 0) {
						this.$message.warning("请先完成支付");
					} else if (res.data.data.status == 1) {
						this.$message.success("购买成功!");
						this.reload_alipay_qr = false;
						this.$router.push({ name: "manage_equipment" });
					}
				})
				.finally(() => {
					this.wechatPay.loading = false;
					this.aliPay.loading = false;
				});
		},
	},
};
</script>
<style scoped lang="less">
.buyequ_wrap {
	min-width: 1200px;
	background: white;
	margin: 0 auto;
	min-height: 700px;
	margin-top: 20px;

	.top_wrap {
		width: 100%;
		max-width: 1879px;
		height: 134px;
		margin: 0 auto;

		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		background-image: url("../../../assets/img/equipment/buytop.png");

		.top_content {
			width: 1200px;
			height: 100%;
			position: relative;
			margin: 0 auto;
			padding-top: 30px;
			//border: 1px solid yellow;
			.top_line {
				display: flex;
				cursor: pointer;
				align-items: center;
				.top_l_left {
					width: 23px;
					height: 20px;

					background-repeat: no-repeat;
					background-position: center;
					background-size: 100%;
					background-image: url("../../../assets/img/equipment/jiantou.png");
				}

				.top_l_right {
					width: 100px;
					margin-left: 8px;
					height: 24px;
					font-size: 24px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #ffffff;
					line-height: 24px;
				}
			}

			.top_down {
				position: absolute;
				left: 79px;
				top: 79px;
				height: 14px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #ffffff;
				line-height: 14px;
			}
		}
	}
	& > main {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		.block {
			padding: 20px;
			box-shadow: 0px 0px 12px 1px rgba(193, 199, 203, 0.3);
			margin-bottom: 18px;
			& > .title {
				height: 14px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #374567;
				line-height: 14px;
				border-left: 4px solid #4c84ff;
				margin-bottom: 20px;
				padding-left: 8px;
			}
		}
		.deviceInfo {
			flex: 0 0 850px;
			max-width: 850px;
			padding-bottom: 100px;
			.hotDeviceList {
				ul {
					display: flex;
					flex-wrap: wrap;
					li {
						position: relative;
						width: 188px;
						height: 85px;
						background: #f7f9fd;
						border: 1px solid #ecf1fc;
						padding: 8px 13px;
						cursor: pointer;
						& + li {
							margin-left: 18px;
						}
						.header {
							span {
								line-height: 38px;
								padding-left: 8px;
							}
						}
						.footer {
							margin-top: 8px;
							.region {
								padding: 0 5px 0 5px;
								text-align: center;
								line-height: 20px;
								height: 20px;
								font-size: 12px;
								color: #4c84ff;
								background: #e1eaff;
								border-radius: 2px;
							}
							.area {
								padding-right: 3px;
								text-align: center;
								line-height: 20px;
								font-size: 12px;
								color: #04b49c;
								background: #d5f6f2;
								border-radius: 2px;
								height: 20px;
								margin-left: 8px;
								padding-left: 19px;
								background-repeat: no-repeat;
								background-position: 3px 2px;
								background-size: 15px 15px;
								background-image: url("../../../assets/images/equipment/icon_area.png");
							}
						}
						.top {
							position: absolute;
							right: -6px;
							top: -3px;
						}
						&.active {
							border: 1px solid #739afe;
							&::after {
								content: "";
								display: block;
								position: absolute;
								width: 20px;
								height: 20px;
								background-image: url("../../../assets/images/equipment/icon_check.png");
								background-size: 100% 100%;
								right: -10px;
								top: -10px;
							}
						}
					}
				}
			}
			.diyNet {
				& > ul {
					& > li {
						display: flex;
						align-items: flex-start;
						margin-top: 10px;
						.left {
							line-height: 30px;
							flex: 0 0 60px;
						}
						.right {
							flex: 0 0 750px;
							ul {
								display: flex;
								width: 750px;
								flex-wrap: wrap;
								li {
									position: relative;
									padding: 0 16px;
									height: 30px;
									line-height: 30px;
									text-align: center;
									cursor: pointer;
									font-size: 14px;
									color: #374567;
									background: #f7f9fd;
									border: 1px solid #ecf1fc;
									margin-right: 20px;
									margin-bottom: 16px;
									&.active {
										border: 1px solid #739afe;
										&::after {
											content: "";
											display: block;
											position: absolute;
											width: 20px;
											height: 20px;
											background-image: url("../../../assets/images/equipment/icon_check.png");
											background-size: 100% 100%;
											right: -10px;
											top: -10px;
										}
									}
								}
							}
							&.setMeal {
								ul {
									display: flex;
									width: 750px;
									flex-wrap: wrap;
									li {
										padding: 10px 16px;
										height: unset;
										p {
											color: #ea5529;
										}
									}
								}
							}
						}
					}
				}
				.tip {
					font-size: 12px;
					margin-top: 20px;
					color: #abb4c3;
				}
			}
			.duration {
				& > ul {
					& > li {
						display: flex;
						align-items: flex-start;
						.left {
							line-height: 30px;
							flex: 0 0 60px;
						}
						.right {
							flex: 0 0 750px;
							ul {
								display: flex;
								width: 750px;
								flex-wrap: wrap;
								li {
									position: relative;
									padding: 0 18px;
									height: 30px;
									line-height: 30px;
									text-align: center;
									cursor: pointer;
									font-size: 14px;
									color: #374567;
									background: #f7f9fd;
									border: 1px solid #ecf1fc;
									margin-right: 30px;
									margin-bottom: 16px;
									&.active {
										border: 1px solid #739afe;
										&::after {
											content: "";
											display: block;
											position: absolute;
											width: 20px;
											height: 20px;
											background-image: url("../../../assets/images/equipment/icon_check.png");
											background-size: 100% 100%;
											right: -10px;
											top: -10px;
										}
									}
									span {
										line-height: 18px;
										text-align: center;
										position: absolute;
										background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAAAkCAYAAAB2UT9CAAAAAXNSR0IArs4c6QAAAjFJREFUaAXtmsEuQ0EUhs+5xAYJGwuJqKVXIJbd2dj0ISQiIvoOKoI08RA2NsSCHfUKlr0sWJKwEhxnbk1zp73Vlttj4Z+kmTtnpvM339/p3Ns5LPVtIZT+CTC9kNA9Md9rfUcRnxONnvLs6mM/kzEM6AdXl7FMb0R8qa8qF7aOu4xOumFAL5R+MobpWldHmWfLte/eHn3Xib5fEBBaoA+5knplX+RoqNNMMKATmdzisk5x/UzqexNZU8KALCr5x4rEr9dZJsCA/GFnzyg0T/R61PpzBAOycQ0qWqQ43k1PDgPSNEyuZV1uK4teCgZ4Epa1SMXLwQBPwrLWW1SJd1acJAywBB9oyZpr4kk4gGLYcH9b8PgUVoAh80BKaJjoeRkGBFSMGxIVYYAx81BOZmBASMS2JTINA2yRh2pMzgB+DqNomREQGlMD5MFMEEJtBKLkTLMtjIAVgSg5ULZSg04bgYgiuWiLImBGQPeA8RNKTvPNNCGUIhA18lhcKgXKXxD4eg7g6l+IQ1MfAjwEibdruiEv+DZqGwJfK0DFNInIRhIqaQJNAxoZXHyQ7sT14Ak0DUikCoVNrTXJFMWKQGAAc+mdaKSkO8ON1Qf47zqBAQ4Gz208kYy4zRgrweDb0bwLatVKMriSJCLNbUQZGIGOBnjFJInI5bHgFtUjybXuaoBXa+SxuFQKWVIz9EAZJQ8CPRvgxeT2cNKd5pMeKKsZM6THarppT6spY34M6t4JfAIs8oT0RdvP7QAAAABJRU5ErkJggg==)
											no-repeat center center;
										background-size: 100% 100%;
										left: -12px;
										top: -9px;
										width: 48px;
										height: 18px;
										font-size: 12px;
										color: #ff0000;
									}
								}
							}
							.count {
								display: flex;
								align-items: center;
								.ant-input-number {
									width: 100px;
									margin-right: 30px;
								}
							}
						}
					}
				}
			}
			.pay_method {
				.payMethods {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					li {
						position: relative;
						display: flex;
						width: 160px;
						margin-right: 30px;
						height: 64px;
						background: #f7f9fd;
						border: 1px solid #ecf1fc;
						cursor: pointer;
						display: flex;
						justify-content: center;
						align-items: center;
						div {
							margin-left: 10px;
							span {
								height: 15px;
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #374567;
								line-height: 15px;
							}
							p {
								height: 12px;
								margin-top: 7px;
								font-size: 12px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								color: #abb4c3;
								line-height: 12px;
							}
						}
						&.active {
							border: 1px solid #739afe;
							&::after {
								content: "";
								display: block;
								position: absolute;
								width: 20px;
								height: 20px;
								background-image: url("../../../assets/images/equipment/icon_check.png");
								background-size: 100% 100%;
								right: -10px;
								top: -10px;
							}
						}
					}
				}
				.coupon {
					::v-deep .ant-select {
						.ant-select-selection {
							padding-left: 36px;
							&::before {
								content: "";
								position: absolute;
								left: 10px;
								top: 8px;
								width: 29px;
								height: 15px;
								background-repeat: no-repeat;
								background-image: url(../../../assets/images/equipment/icon_coupon.png);
								background-size: contain;
								z-index: 9;
								padding-right: 10px;
								border-right: 1px solid #ecf1fc;
							}
						}
					}
				}
				.autoRenewal {
					margin-top: 20px;
				}
			}
		}
		.orderInfo {
			flex: 0 0 330px;
			position: sticky;
			top: 0px;
			box-shadow: 0px 0px 12px 1px rgba(193, 199, 203, 0.3);
			height: 650px;
			& > .title {
				line-height: 45px;
				padding-left: 30px;
				height: 45px;
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: 500;
				color: #374567;
				border-bottom: 1px solid #e7ebf1;
			}
			& > .info {
				padding: 0 0 0 30px;
				padding-bottom: 16px;
				background-image: url(../../../assets/images/equipment/info_bg.png);
				background-repeat: no-repeat;
				background-position: right bottom;
				li {
					height: 36px;
					line-height: 36px;
					color: #425070;
				}
			}
			& > .order {
				padding: 10px 30px;
				border-top: 1px solid #e7ebf1;
				.cost {
					height: 36px;
					display: flex;
					justify-content: space-between;
					span {
						font-size: 16px;
						color: #425070;
						&:last-child {
							color: #ea5529;
						}
					}
				}
				.btn {
					height: 44px;
					margin-top: 26px;
					cursor: pointer;
					text-align: center;
					line-height: 44px;
					background: linear-gradient(
						90deg,
						#eeb732 0%,
						#ee9a32 100%
					);
					border-radius: 2px;
					border: 0px;
					font-size: 14px;
					font-weight: 400;
					color: #ffffff;
				}
				.agree {
					margin-top: 19px;
					span {
						b {
							color: #4c84ff;
							font-weight: normal;
						}
					}
				}
			}
		}
	}
}
::v-deep .ant-modal {
	.qr_box {
		display: flex;
		align-items: center;
		justify-content: center;
		.tip {
			font-style: 16px;
			font-weight: bold;
		}
	}
}
</style>
